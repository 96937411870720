import React from "react";
import Banner from "../elements/Banner";
import BookingButton from "../elements/BookingButton";

import girlBraces from "../../images/girl-braces.jpg";
import useWindowDimensions from "../../useWindowDimensions";
import PageTitle from "../elements/PageTitle";

export default function Insurance() {
  const { width } = useWindowDimensions();

  return (
    <React.Fragment>
      <PageTitle
        title="Zahnzusatzversicherung"
        description="Für gesetzlich Versicherte fast immer empfehlenswert."
        canonical="zusatzversicherung"
      />
      <div className="page-content insurance-page bg-white">
        <section className="section-area section-sp1">
          <Banner title="Zahnzusatzversicherung">
            {width < 520 ? (
              <>
                Zahnzusatz-
                <br />
                versicherung
              </>
            ) : (
              "Zahnzusatzversicherung"
            )}
          </Banner>
          <div className="container">
            <h3 className="text-center mt-40">
              Für gesetzlich Versicherte fast immer empfehlenswert.
            </h3>
            <div className=" mt-40">
              <p>Sie sind oder Ihr Kind ist Kassenpatient*in?</p>
              <p>
                Im Bestfall schließen Sie eine Zahnzusatzversicherung für sich
                oder Ihr Kind ab, <u>bevor</u> Sie zu uns für eine Untersuchung
                kommen! Denn ist von uns erst einmal eine behandlungsbedürftige
                Fehlstellung diagnostiziert, ist es sehr schwierig, einen
                Versicherungsabschluss zu erhalten.
              </p>
              <p>
                Achten Sie unbedingt darauf, dass die Kieferorthopädie mit
                versichert ist und auf Leistungsbegrenzungen, z. B. in den
                ersten Versicherungsjahren.
              </p>
              <p>
                Des Weiteren sollten Sie darauf achten, dass hohe Summen
                versichert sind. Und das nicht nur für die Schweregrade
                ("Kieferorthopädische Indikationsgruppen") 3 bis 5, sondern auch
                1 bis 2. Denn nur so profitieren Sie von einem
                Versicherungsschutz, wenn die gesetzliche Versicherung gar
                nichts dazu zahlen sollte.
              </p>
              <p>
                Vergleichen Sie die Tarife am besten auf unabhängigen Seiten wie
                bei der{" "}
                <u>
                  <a
                    target="_blank"
                    href="https://www.test.de/Zahnzusatzversicherung-im-Test-4730314-0/"
                    rel="noreferrer"
                  >
                    Stiftung Warentest
                  </a>
                </u>{" "}
                oder bei der{" "}
                <u>
                  <a
                    target="_blank"
                    href="https://www.waizmanntabelle.de/kinder"
                    rel="noreferrer"
                  >
                    Waizmanntabelle{" "}
                  </a>
                </u>{" "}
                und lassen Sie sich ausgiebig beraten.
              </p>
              <p>
                Es wurde bei Ihrem Kind schon eine kieferorthopädische
                Behandlungsbedürftigkeit festgestellt? Wenn es also eigentlich
                schon zu spät ist, gibt es wohl nur noch vereinzelt
                Versicherungen, die Sie abschließen können. Am besten
                informieren Sie sich hierzu im Internet.
              </p>
              <p>
                Dies stellt keine Beratung über private Zahnzusatzversicherungen
                dar. Irrtümer sind vorbehalten, Informationen über die genauen
                Leistungen und Beschränkungen erhalten Sie bei den jeweiligen
                Versicherern.
              </p>
              <img
                className="mt-20"
                src={girlBraces}
                style={{ borderRadius: "2rem" }}
              />
              <BookingButton
                text="Jetzt Termin buchen!"
                classes="btn-primary mt-50 mb-20 d-block mx-auto"
              />
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
