import React from "react";
import Banner from "../elements/Banner";
import BookingButton from "../elements/BookingButton";
import PageTitle from "../elements/PageTitle";

export default function PrivateTreatment() {
  const options = [
    {
      heading: "Transparent",
      content:
        "Es wird kaum jemandem auffallen, dass Sie gerade Ihre Zähne richten lassen.",
    },
    {
      heading: "Für hohen Tragekomfort entwickelt",
      content: "Individuell gefertigt, weich und angenehm zu tragen.",
    },
    {
      heading: "Herausnehmbar",
      content:
        "Das Leben geht weiter wie bisher – von der normalen Mundhygiene bis zu Ihren Essgewohnheiten. Die empfohlene Tragezeit für Aligner liegt bei 22 Stunden täglich.",
    },
    {
      heading: "Schnell",
      content:
        "Mit wöchentlichen Aligner-Wechseln können Sie in bereits 3 Monaten geradere Zähne erreichen.",
    },
    {
      heading: "Bewährt",
      content:
        "Das SmartTrackMaterial, das für die Invisalign-Aligner verwendet wird, hat sich klinisch dabei bewährt, die Zahnbewegung zu verbessern, und ist effizienter als andere transparente Aligner-Materialien.",
    },
    {
      heading: "15 Millionen Lächeln",
      content:
        "Über 15 Millionen von Menschen haben mit den transparenten Invisalign-Alignern weltweit bereits ein neues Lächeln erhalten.",
    },
  ];

  const listOptions = [
    {
      heading: "Express",
      items: [
        "Erwachsene",
        "bis zu zwei Runden mit jeweils maximal 7 Aligner",
        "Behandlungszeit: 3 bis 12 Monate",
        "inkl. 3 Sets Vivera-Retainer",
      ],
    },
    {
      heading: "Lite/Moderate",
      items: [
        "Jugendliche und Erwachsene",
        "bis zu drei Runden mit jeweils maximal 20 Aligner",
        "Behandlungszeit: 6 bis 24 Monate",
        "inkl. 3 Sets Vivera-Retainer",
      ],
    },
    {
      heading: "Comprehensive",
      items: [
        "Jugendliche und Erwachsene",
        "unbegrenzt viele Runden und Aligner",
        "Behandlungszeit: 1 bis 4 Jahre",
        "inkl. 3 Sets Vivera-Retainer",
      ],
    },
    {
      heading: "First/Comprehensive",
      items: [
        "Kinder und Jugendliche",
        "unbegrenzt viele Runden und Aligner",
        "Behandlungszeit: 1 bis 4 Jahre",
        "inkl. 3 Sets Vivera-Retainer",
      ],
    },
  ];

  return (
    <React.Fragment>
      <PageTitle
        title="Private Behandlung"
        description="Die modernste Methode einer kieferorthopädischen Behandlung stellt die Aligner-Therapie mit durchsichtigen, unauffälligen Schienen dar."
        canonical="privatbehandlung"
      />
      <div className="page-content private-treatment bg-white">
        <section className="section-area section-sp1">
          <Banner title="Private Behandlung" />
          <div className="private-treatment__content container">
            <p>
              Die modernste Methode einer kieferorthopädischen Behandlung stellt
              die Aligner-Therapie mit durchsichtigen, unauffälligen Schienen
              dar.
            </p>
            <p>
              Als spezialisierte Invisalign-Anwender ist es uns als eine von
              wenigen Praxen in unserer Region, alle Fehlstellungen mit dem
              einzigartigen System vom Pionier der Aligner zu korrigieren.
            </p>
            <p>
              Auch Kinder und Jugendliche können mithilfe des Invisalign-Systems
              behandelt werden.
            </p>
            <p>
              Diese Art von Behandlung wird von den gesetzlichen
              Krankenversicherungen leider nicht getragen.
            </p>
            <p>
              Aufgrund der zahlreichen Vorteile dieser Methode, behandeln wir
              Privatpatient*innen und Selbstzahler*innen grundsätzlich mit
              Alignern.
            </p>

            <div style={{ position: "relative" }}>
              <iframe
                src="https://www.youtube.com/embed/9g8bTWZZAPY?si=kA6JKamyDMnEKSZf"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
            <h3 className="private-treatment__content__heading mt-50 ">
              Unsichtbare Zahnspange
            </h3>

            <p className="mt-20">
              Das Invisalign-System ist eine nahezu unsichtbare Behandlung, die
              die Zähne effektiv begradigt. Bei der Invisalign-Behandlung kommt
              eine Reihe individuell angefertigter, herausnehmbarer Aligner mit
              der besonderen SmartForce-Technologie zum Einsatz, die Ihre Zähne
              stufenweise in die ideale Position bewegen.
            </p>
            <p>
              Die Vorteile gegenüber einer klassischen Bracket-Behandlung liegen
              auf der Hand:
            </p>
            {options.map((x, i) => {
              return (
                <>
                  <p className="private-treatment__content--bold">
                    {x.heading}
                  </p>
                  <ul>
                    <li>{x.content}</li>
                  </ul>
                </>
              );
            })}
            <BookingButton
              text="Buchen Sie jetzt Ihren persönlichen Beratungstermin!"
              classes="btn-primary mt-10"
            />
          </div>
          <div className="container mt-50">
            <h2>Kosten</h2>
            <p>
              Die Behandlungskosten richten sich danach, wie schwierig die
              Behandlung ist und wie viele Aligner hierfür benötigt werden.
              Selten benötigen wir auch zusätzliche Zahnspangen wie eine GNE
              oder Fränkel III.
            </p>
            <p>
              Wir haben uns für eine transparente Aufstellung der Kosten in vier
              Kategorien, je nach Schwierigkeitsgrad der Behandlung sowie je
              nach Invisalign-Produkt, entschieden. In welche Sie bzw. Ihr Kind
              fallen, erörtern wir nach der Erstuntersuchung bei uns in der
              Praxis.
            </p>
            <p>
              <u>
                Die Kosten liegen zwischen rund 4.000 und 10.000 €, je nach
                Aufwand und Art der Behandlung.
              </u>
            </p>
            <p>
              Bei Kassenpatient*innen prüfen wir in der für Sie kostenfreien
              Erstuntersuchung, die wir über die gesetzliche Versicherung
              abrechnen, ob Sie oder Ihr Kind eine Kassenbehandlung erhalten
              können oder nicht. Falls nicht, sind sämtliche Folgekosten privat
              zu entrichten.
            </p>

            <p>
              In den unten genannten Behandlungskosten sind <u>nicht</u> die
              diagnostischen Kosten (Scans, Fotos, Analysen, Röntgenbilder usw.)
              samt Aufstellung des Heil- und Kostenplans inkludiert.
              <p>
                Align Technology stellt für den digitalen Behandlungsplan
                ("ClinCheck") außerdem rund 300 € in Rechnung, insofern man sich
                gegen eine Behandlung entscheiden sollte. Fremdlaborkosten geben
                wir stets an Sie weiter.
              </p>
              <p>
                Bitte beachten Sie dabei, dass selbstverständlich dennoch jeder
                Fall gemäß Gebührenordnung der Zahnärzte (GOZ) individuell
                berechnet wird und die Kosten (leicht) abweichen können.
              </p>
            </p>

            <div className="row mt-30">
              {listOptions.map((x, i) => {
                return (
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <h5>{x.heading}</h5>
                    <ul className="mt-20">
                      {x.items.map((item, i) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
