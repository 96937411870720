import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

import useWindowDimensions from "../../useWindowDimensions";

// Images
import logo from "../../images/logo-3.png";
import logoWhite from "../../images/logo-2.png";
import { Context } from "../../context";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchFormOpen, setIsSearchBtn] = useState(false);
  const quikSearchBtn = () => setIsSearchBtn(!isSearchFormOpen);
  const quikSearchClose = () => setIsSearchBtn(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);

  const { selectedFlow, setSelectedFlow } = React.useContext(Context);

  const toggleSubmenu = (item) => {
    setActiveItem(item === activeItem ? null : item);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleMenuLinkClick = () => {
    if (window.innerWidth <= 991) {
      setIsMenuOpen(false);
    }
  };

  const handleContactBtnClick = () => {
    setIsMenuOpen(false);
    // Implement the logic to toggle the contact sidebar here.
  };

  const handleMenuCloseClick = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth >= 768);
    };

    // Check the screen size on initial render and whenever the window is resized
    handleResize();

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { width } = useWindowDimensions();

  const orthoItems = [
    { id: "home", name: "Home", linkName: "home" },
    { id: "appliances", name: "Zahnspangen", linkName: "zahnspangen" },
    {
      id: "behandlungsarten",
      name: "Behandlungsarten",
      linkName: "",
      subItems: [
        {
          id: "public-treatment",
          displayName: "Kassenbehandlung",
          linkName: "kassenbehandlung",
        },
        {
          id: "privatbehandlung",
          displayName: "Privatbehandlung",
          linkName: "privatbehandlung",
        },
      ],
    },
    { id: "cmd", name: "CMD und Bruxismus", linkName: "cmd-bruxismus" },
    {
      id: "lips",
      name: "Lippenunterspritzung",
      linkName: "lippenunterspritzung",
    },
    {
      id: "finanzen",
      name: "Finanzen",
      linkName: "",
      subItems: [
        {
          id: "zusatzversicherung",
          displayName: "Zusatzversicherung",
          linkName: "zusatzversicherung",
        },
        {
          id: "ratenzahlung",
          displayName: "Ratenzahlung",
          linkName: "ratenzahlung",
        },
        {
          id: "kostenerstattung",
          displayName: "Kostenerstattung",
          linkName: "kostenerstattung",
        },
      ],
    },
    {
      id: "more",
      name: "Mehr",
      linkName: "",
      subItems: [
        { id: "faq", displayName: "FAQ", linkName: "faq" },
        { id: "kontakt", displayName: "Kontakt", linkName: "kontakt" },
        {
          id: "karriere",
          displayName: "Karriere",
          linkName: "karriere",
          externalLink:
            "https://www.karriere-fliegender-zahn.de/wir-suchen-sie/?utm_source=ig&utm_medium=Instagram_Feed&utm_campaign=adset2&utm_content=170125&utm_id=120214256313650785&utm_term=120214256313630785",
        },
        {
          id: "pediatric",
          displayName: "Kinder- und Jugendzahnheilkunde",
          linkName: "home",
        },
      ],
    },
  ];

  const pediatrictItems = [
    { id: "home", name: "Home", linkName: "home" },
    { id: "prophylaxe", name: "Prophylaxe", linkName: "prophylaxe" },
    {
      id: "diagnostik",
      name: "Diagnostik",
      linkName: "diagnostik",
    },
    {
      id: "behandlungen",
      name: "Behandlungen",
      linkName: "behandlungen",
    },
    {
      id: "sedierung",
      name: "Therapiehund und Sedierung",
      linkName: "sedierung",
    },
    {
      id: "finanzen",
      name: "Finanzen",
      linkName: "",
      subItems: [
        {
          id: "zusatzversicherung",
          displayName: "Zusatzversicherung",
          linkName: "zusatzversicherung",
        },
        {
          id: "ratenzahlung",
          displayName: "Ratenzahlung",
          linkName: "ratenzahlung",
        },
        {
          id: "kostenerstattung",
          displayName: "Kostenerstattung",
          linkName: "kostenerstattung",
        },
      ],
    },
    {
      id: "more",
      name: "Mehr",
      linkName: "",
      subItems: [
        // { id: "faq", displayName: "FAQ", linkName: "faq" },
        { id: "kontakt", displayName: "Kontakt", linkName: "kontakt" },
        {
          id: "karriere",
          displayName: "Karriere",
          linkName: "karriere",
          externalLink:
            "https://www.karriere-fliegender-zahn.de/wir-suchen-sie/?utm_source=ig&utm_medium=Instagram_Feed&utm_campaign=adset2&utm_content=170125&utm_id=120214256313650785&utm_term=120214256313630785",
        },
        { id: "ortho", displayName: "Kieferorthopädie", linkName: "home" },
      ],
    },
  ];

  const transformItems = (items, widthThreshold) => {
    if (width >= widthThreshold) return items;

    return items.reduce((acc, item) => {
      if (item.subItems) {
        const transformedSubItems = item.subItems.map((subItem) => ({
          ...subItem,
          name: subItem.displayName,
        }));
        return [...acc, ...transformedSubItems];
      } else {
        return [...acc, item];
      }
    }, []);
  };

  const transformedOrthoItems = transformItems(orthoItems, 1200);
  const transformedPediatricItems = transformItems(pediatrictItems, 1200);

  const menuItems =
    selectedFlow === "ortho"
      ? transformedOrthoItems
      : transformedPediatricItems;

  //   const menuItems = [
  //     {
  //       id: "home",
  //       name: "Home",
  //       linkName: "#",
  //     },
  //     {
  //       id: "pages",
  //       name: "Pages",
  //       linkName: "#",

  //       subItems: [
  //         {
  //           id: "aboutus",
  //           displayName: "About Us",
  //           linkName: "about-us",
  //         },
  //         {
  //           id: "team",
  //           displayName: "Our Team",
  //           linkName: "team",
  //         },
  //         {
  //           id: "faq",
  //           displayName: "FAQ's",
  //           linkName: "faq",
  //         },
  //         {
  //           id: "booking",
  //           displayName: "Booking",
  //           linkName: "booking",
  //         },
  //         {
  //           id: "error",
  //           displayName: "Error 404",
  //           linkName: "error-404",
  //         },
  //         {
  //           id: "formLogin",
  //           displayName: "Login / Register",
  //           linkName: "form-login",
  //         },
  //       ],
  //     },
  //     {
  //       id: "services",
  //       name: "Services",
  //       linkName: "",

  //       subItems: [
  //         {
  //           id: "services",
  //           displayName: "Service",
  //           linkName: "services",
  //         },
  //         {
  //           id: "serviceDetail",
  //           displayName: "Service Detail",
  //           linkName: "service-detail",
  //         },
  //       ],
  //     },
  //     {
  //       id: "blog",
  //       name: "Blog",
  //       linkName: "#",

  //       subItems: [
  //         {
  //           id: "blogGrid",
  //           displayName: "Blogs",
  //           linkName: "blog-grid",
  //         },
  //         {
  //           id: "blogDetails",
  //           displayName: "Blog Details",
  //           linkName: "blog-details",
  //         },
  //       ],
  //     },
  //     {
  //       id: "contactUs",
  //       name: "Contact Us",
  //       linkName: "contact-us",
  //     },
  //   ];

  return (
    <header className="header header-transparent rs-nav">
      <Sticky enabled={true} className="sticky-header navbar-expand-xl">
        <div className="menu-bar clearfix">
          <div className="container-fluid clearfix">
            {/* <div className="menu-logo logo-dark">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div> */}

            <button
              className={`navbar-toggler collapsed menuicon justify-content-end ${
                isMenuOpen ? "open" : ""
              }`}
              type="button"
              onClick={toggleMenu}
              aria-label="Toggle navigation"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>

            <div
              className={`menu-links navbar-collapse collapse justify-content-center ${
                isMenuOpen ? "show" : ""
              }`}
              id="menuDropdown"
            >
              <div className="menu-logo">
                <Link to="/">
                  <img src={logoWhite} alt="" />
                </Link>
              </div>

              <ul className="nav navbar-nav">
                {menuItems.map((item) => (
                  <li
                    key={item.id}
                    className={`${activeItem === item.id ? "open" : ""}`}
                    onClick={() => toggleSubmenu(item.id)}
                  >
                    {item.subItems ? (
                      <Link
                        to="#"
                        onClick={() => {
                          if (item.id !== "more") {
                            if (
                              item.id === "ortho" ||
                              item.id === "pediatric"
                            ) {
                              window.scrollTo(0, 0);
                              setSelectedFlow(item.id);
                            }
                            handleMenuLinkClick();
                          }
                        }}
                      >
                        {item.name}
                        <i className={`fas fa-plus`}></i>
                      </Link>
                    ) : (
                      <Link
                        to={item.externalLink ? "#" : `/${item.linkName}`}
                        onClick={() => {
                          if (item.externalLink) {
                            window.open(item.externalLink, "_blank");
                          }
                          if (item.id !== "more") {
                            if (
                              item.id === "ortho" ||
                              item.id === "pediatric"
                            ) {
                              window.scrollTo(0, 0);
                              setSelectedFlow(item.id);
                            }
                            handleMenuLinkClick();
                          }
                        }}
                      >
                        {item.name}
                      </Link>
                    )}
                    {(isMobileView || activeItem === item.id) &&
                      item.subItems && (
                        <ul className="sub-menu">
                          {item.subItems.map((subItem, index) => (
                            <li key={subItem.id}>
                              <Link
                                to={
                                  subItem.externalLink
                                    ? "#"
                                    : `/${subItem.linkName}`
                                }
                                onClick={() => {
                                  if (subItem.externalLink) {
                                    window.open(subItem.externalLink, "_blank");
                                  }
                                  if (
                                    subItem.id === "ortho" ||
                                    subItem.id === "pediatric"
                                  ) {
                                    window.scrollTo(0, 0);
                                    setSelectedFlow(subItem.id);
                                  }
                                  handleMenuLinkClick();
                                }}
                              >
                                <span>{subItem.displayName}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                  </li>
                ))}
              </ul>

              <ul className="social-media">
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/fliegender_zahn/"
                    className="btn btn-primary"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="tel:+4953122458888"
                    className="btn btn-primary"
                  >
                    <i className="fa fa-phone-alt"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:praxis@fliegender-zahn.de"
                    className="btn btn-primary"
                  >
                    <i className="fa fa-envelope"></i>
                  </a>
                </li>
              </ul>

              <div className="menu-close" onClick={handleMenuCloseClick}>
                <i className="ti-close"></i>
              </div>
            </div>
          </div>
        </div>
      </Sticky>

      <div className={`nav-search-bar ${isSearchFormOpen ? "show" : ""}`}>
        <form>
          <input
            type="text"
            className="form-control"
            placeholder="Type to search"
          />
          <span>
            <i className="ti-search"></i>
          </span>
        </form>
        <span id="searchRemove" onClick={quikSearchClose}>
          <i className="ti-close"></i>
        </span>
      </div>
    </header>
  );
};

export default Header;
