import React, { useContext } from "react";
import Banner from "../elements/Banner";

import piggyjar from "../../images/piggyjar.jpg";
import PageTitle from "../elements/PageTitle";
import { Context } from "../../context";

export default function Installment() {
  const { selectedFlow } = useContext(Context);
  const isOrtho = selectedFlow === "ortho";
  console.log(isOrtho);

  const months = isOrtho ? 24 : 6;

  return (
    <React.Fragment>
      <PageTitle
        title="Ratenzahlung"
        description="Eine medizinisch notwendige Behandlung sollte immer durchgeführt
              werden und nicht am Kontostand scheitern. Dies gilt natürlich auch
              für sinnvolle Zusatzleistungen bei Kassenbehandlungen, also
              sprechen Sie uns gern an!"
        canonical="ratenzahlung"
      />
      <div className="page-content installment-page bg-white">
        <section className="section-area section-sp1">
          <Banner title="Ratenzahlung" />
          <div className="container">
            <h3 className="mt-30 text-center">
              bis zu {months} Monate zinsfrei
            </h3>

            <p className="text-justify">
              Eine medizinisch notwendige Behandlung sollte immer durchgeführt
              werden und nicht am Kontostand scheitern. Dies gilt natürlich auch
              für sinnvolle Zusatzleistungen bei Kassenbehandlungen, also
              sprechen Sie uns gern an!
            </p>

            {isOrtho && (
              <p className="text-justify">
                Sowohl der abschlagsweise Eigenanteil von 20 % bzw. 10 %, als
                auch die privaten Zusatzleistungen werden Ihnen quartalsweise in
                Rechnung gestellt. Nicht immer kann man es sich leisten, die
                volle Höhe sofort zu bezahlen.
              </p>
            )}

            <p className="text-justify">
              Aus diesem Grund arbeiten wir für unsere Rechnungen mit einem
              Factoring-Unternehmen zusammen. So können wir Ihnen eine zinsfreie
              Ratenzahlung von bis zu {months} Monaten anbieten.
            </p>

            <p className="text-justify">
              Teilzahlungen für bis zu 72 Monate sind ebenfalls möglich, hier
              fallen jedoch individuelle, marktübliche Zinsen gegenüber dem
              Factoring-Unternehmen an.
            </p>

            <p className="text-justify">
              Das Gute: Sie können mit jeder Rechnung neu entscheiden, ob sie
              diese in Raten zahlen möchten oder nicht.
            </p>
            <img
              className="mt-20"
              src={piggyjar}
              style={{ borderRadius: "2rem" }}
            />
            {/* <button
              onClick={() => {
                window.open(
                  "https://meinebfs.de/patienten/uebersicht/",
                  "_blank"
                );
              }}
              className="btn btn-primary mt-50 d-block mx-auto"
            >
              Infos zu BFS health finance
            </button> */}
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
