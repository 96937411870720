import React from "react";
import Banner from "../elements/Banner";

// import bruxismus from "../../images/bruxismus.jpeg";
import bruxismus2 from "../../images/private-treatment/bruxismus2.jpg";

import bruxismus from "../../images/private-treatment/bruxismus.jpg";
import cmd from "../../images/private-treatment/cmd2.jpg";
import botulinumtoxin from "../../images/private-treatment/botulinumtoxin.jpg";
import musculus from "../../images/private-treatment/musculus.jpg";
// import useWindowDimensions from "../../useWindowDimensions";
import BookingButton from "../elements/BookingButton";
import PageTitle from "../elements/PageTitle";

export default function Bruxismus() {
  // const { width } = useWindowDimensions();
  return (
    <React.Fragment>
      <PageTitle
        title="CMD/Bruxismus"
        description="CMD/Bruxismus"
        canonical="bruxismus"
      />
      <div className="page-content bruxismus-page bg-white">
        <section className="section-area section-sp1">
          <Banner title="CMD/Bruxismus" />
          <div className="container">
            <h2>Endlich schmerzfrei</h2>
            <p>
              Zähnepressen, Zähneknirschen, Kiefergelenkknacken,
              Kiefergelenkschmerzen und vieles mehr...
            </p>

            <div className="row mt-50">
              <div className="col-md-6">
                <h5>Bruxismus</h5>
                <p>
                  ist das unbewusste, meist nächtliche, aber auch tagsüber
                  ausgeführte Zähneknirschen oder Aufeinanderpressen der Zähne.
                  Dies führt regelmäßig zu starken Muskelverspannungen im
                  Kiefer, Nacken und Rücken, nicht selten auch zu akuten
                  Schmerzzuständen.
                </p>
                <img
                  className="bruxismus-page__image-container__image mt-20 mb-20"
                  src={bruxismus}
                />
              </div>
              <div className="col-md-6">
                <h5>Musculus Masseter</h5>
                <p>
                  Bei beiden Krankheitsbildern ist oftmals eine zu hohe Funktion
                  dieses Hauptkaumuskels mit verantwortlich.
                </p>
                <img
                  className="bruxismus-page__image-container__image mt-20"
                  src={musculus}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-18 bruxismus-page__image-container ">
                {/* <img
                className="bruxismus-page__image-container__image mt-20 mb-20"
                src={cmd}
              /> */}
              </div>

              <div className="col-md-6 mt-30">
                <h5>CMD</h5>

                <p>
                  ist die Abkürzung für Craniomandibuläre Dysfunktion und
                  bezeichnet die Störung der Muskel- oder Gelenkfunktion der
                  Kiefergelenke.
                  <br />
                  Diese Fehlregulationen können schmerzhaft sein oder Ursache
                  für Schwindelgefühle, Kopfschmerzen, Lichtempfindlichkeit,
                  Panikattacken (Herzrasen) und Stress im Alltag.
                </p>
                <img
                  className="bruxismus-page__image-container__image "
                  src={cmd}
                />
              </div>
              <div className="col-md-6 mt-30">
                <h5>Botulinumtoxin</h5>

                <p>
                  Insofern Therapien mit Aufbissbehelfen ("Knirscherschienen")
                  und/oder Physiotherapie gescheitert sind oder eine schnelle
                  Schmerzreduktion notwendig ist, kann man Botulinumtoxin in den
                  musculus masseter einspritzen. Dies führt zu einer Reduktion
                  der Muskelaktivität, dadurch zu deutlich weniger Zähnepressen
                  und -knirschen und damit zu bedeutend weniger Beschwerden.
                  Leider wird diese Behandlungsmethode nicht von der
                  gesetzlichen Krankenversicherung übernommen. Die Kosten
                  belaufen sich auf ca. 500 € je Behandlung.
                </p>
                <img
                  className="bruxismus-page__image-container__image "
                  src={botulinumtoxin}
                />
              </div>
            </div>

            <div className="bruxismus-page__start-termin">
              <h3>Starten Sie endlich in ein schmerzfreies Leben!</h3>
              <p className="text-justify mt-30">
                Bei einer jeden Untersuchung schauen wir uns ausgiebig Ihre
                Kiefergelenke und Kaumuskulatur an. Gemeinsam erörtern wir,
                welche Therapie für Sie die sinnvollste ist. In der Regel
                beginnen wir mit einer Schienentherapie. Hierzu führen wir
                ausführliche Analysen durch, die Sie in der Regel rund 160 €
                kosten. Gegebenenfalls ist auch eine zusätzliche Physiotherapie
                von Nöten. Manchmal macht eine Therapie mit Botulinumtoxin Sinn.
              </p>
              <p className="text-justify">
                Herr Milan Keyhani, M. Sc. ist einer der wenigen für
                Botulinumtoxin zertifizierten Zahnärzte in Braunschweig und
                damit Ihr Ansprechpartner für die schnelle und unkomplizierte
                Behandlung Ihres Bruxismus bzw. Ihrer CMD mit Botulinumtoxin!
              </p>
              <p className="text-justify">
                Als Hauptursachen für Bruxismus und eine folgende CMD werden
                jedoch Stress und/oder Zahn- und Kieferfehlstellungen angesehen.
                Daher empfehlen wir Im Falle von Stress, Ihre
                Krankenversicherung nach Kursen wie Autogenes Training oder
                Progressive Muskelentspannung zu fragen. Bei Zahn- und
                Kieferfehlstellungen beraten wir Sie gern hinsichtlich einer
                Korrektur. Schließlich ist es immer am sinnvollsten, an den
                Ursachen und nicht nur an den Symptomen zu arbeiten.
              </p>
              <p className="text-justify">
                Buchen Sie jetzt Ihren kostenfreien Beratungstermin.
              </p>
              <img
                className="bruxismus-page__image-container__image mt-20"
                src={bruxismus2}
              />
              <BookingButton
                text="Terminbuchung"
                classes="btn-primary mx-auto d-block mt-30"
              />
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
